
var isMobile = false; //initiate as false
// device detection
if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
    isMobile = true;
}

var $element = $('#somos');
var $win = $(window);

$win.on('scroll', function () {
    var value_seen = percentageSeen();
    console.log(value_seen);
    $("#linea_somos").css("height", (percentageSeen()-10) * 1.6 + "%")
    $("#linea_somos").css("width", (percentageSeen()-10) * 1.6 + "%")
});

function percentageSeen() {
    var viewportHeight = $(window).height(),
        scrollTop = $win.scrollTop(),
        elementOffsetTop = $element.offset().top,
        elementHeight = $element.height();


    if (elementOffsetTop > (scrollTop + viewportHeight)) {
        return 0;
    } else if ((elementOffsetTop + elementHeight) < scrollTop) {
        return 100;
    } else {
        var distance = (scrollTop + viewportHeight) - elementOffsetTop;
        var percentage = distance / ((viewportHeight + elementHeight) / 100);
        percentage = Math.round(percentage);
        return percentage;
    }
}

$win.trigger('scroll');

var $element2 = $('#exito');
var $win2 = $(window);

$win2.on('scroll', function () {
    var value_seen = percentageSeen2();
    console.log(value_seen);
    $("#linea_exito").css("height", percentageSeen2() * 1.1 + "%")
    $("#linea_exito").css("width", percentageSeen2() * 1.1 + "%")
});

function percentageSeen2() {
    var viewportHeight = $(window).height(),
        scrollTop = $win.scrollTop(),
        elementOffsetTop = $element2.offset().top,
        elementHeight = $element2.height();


    if (elementOffsetTop > (scrollTop + viewportHeight)) {
        return 0;
    } else if ((elementOffsetTop + elementHeight) < scrollTop) {
        return 100;
    } else {
        var distance = (scrollTop + viewportHeight) - elementOffsetTop;
        var percentage = distance / ((viewportHeight + elementHeight) / 100);
        percentage = Math.round(percentage);
        return percentage;
    }
}

$win2.trigger('scroll');

var $element3 = $('#descanso');
var $win3 = $(window);

$win3.on('scroll', function () {
    var value_seen = percentageSeen3();
    console.log(value_seen);
    $("#linea_descanso").css("height", (percentageSeen3()-10) * 3.2 + "%")
    $("#linea_descanso").css("width", (percentageSeen3()-10) * 3.2 + "%")
});

function percentageSeen3() {
    var viewportHeight = $(window).height(),
        scrollTop = $win.scrollTop(),
        elementOffsetTop = $element3.offset().top,
        elementHeight = $element3.height();


    if (elementOffsetTop > (scrollTop + viewportHeight)) {
        return 0;
    } else if ((elementOffsetTop + elementHeight) < scrollTop) {
        return 100;
    } else {
        var distance = (scrollTop + viewportHeight) - elementOffsetTop;
        var percentage = distance / ((viewportHeight + elementHeight) / 100);
        percentage = Math.round(percentage);
        return percentage;
    }
}

$win3.trigger('scroll');


var i = 0;                     //  set your counter to 1
var arr = $('.st0').get();
function myLoop () { 
   setTimeout(function () {
      i++;
      if (i < arr.length+50) {
         var el = arr.splice(Math.floor(Math.random() * arr.length), 1);  
         $(el).css('stroke', "white");           
         myLoop();
      }
   }, 50)
}
myLoop();


(function ($) {
    $.fn.goTo = function () {
        $('html, body').animate({
            scrollTop: $(this).offset().top + 'px'
        }, 'fast');
        return this; // for chaining...
    }
})(jQuery);
var lastScrollTop = 0;
var timer, seccion;

// Init WOW.js and get instance
var wow = new WOW();
wow.init();

$(".middle_section").click(function () {
    var siguiente = $(this).closest('section').next();
    scroll(siguiente);
});

$(".send2contact").click(function () {
    scroll($("footer"));
});
$(".nav_right").click(function () {
    $("#menu").css("opacity", "1");
    $("#menu").css("transform", "translate(0%)");
    $("#menu").css("pointer-events", "all");
});
$("#close").click(function () {
    $("#menu").css("transform", "translate(-100%)");
    $("#menu").css("pointer-events", "none");
    setTimeout(function () {
        $("#menu").css("opacity", "0");
        $("#menu").css("pointer-events", "none");
        $("#menu").css("transform", "translate(100%)");
        setTimeout(function () {
            $("#menu").css("display", "flex");
        }, 2000);
    }, 2000);
});

$(".pikele").click(function () {
    var box = $(this).data("section");
    console.log(box);
    if (box.includes("index.html")) {
        window.location.replace(box);
    }
    scroll($(box));
    $("#menu").css("transform", "translate(-100%)");
    setTimeout(function () {
        $("#menu").css("display", "none");
        $("#menu").css("transform", "translate(100%)");
        setTimeout(function () {
            $("#menu").css("display", "flex");
        }, 500);
    }, 2000);
});


$(document).on('click', 'a[href^="#"]', function (e) {
    // target element id
    var id = $(this).attr('href');

    // target element
    var $id = $(id);
    if ($id.length === 0) {
        return;
    }

    // prevent standard hash navigation (avoid blinking in IE)
    e.preventDefault();

    // top position relative to the document
    var pos = $id.offset().top;

    // animated top scrolling
    $('body, html').animate({
        scrollTop: pos
    });
});

function scroll(element) {
    var pos = element.offset().top;
    $('body, html').animate({
        scrollTop: pos
    });
}

$("#linea_hero").css("padding-top",$("#esquina").position()["top"] + 55);
$("#linea_hero").css("padding-left",$("#esquina").position()["left"]+2);


var $win4 = $(window);

$win4.on('scroll', function () {
    var value_seen = percentageSeen3();
    console.log(value_seen);
    $("#linea_hero").css("height", percentageSeen3() * 10 + "%")
    $("#linea_hero").css("width", percentageSeen3() * 10 + "%")
});

function percentageSeen3() {
    var viewportHeight = $(window).height(),
        scrollTop = $win.scrollTop(),
        elementOffsetTop = $element3.offset().top,
        elementHeight = $element3.height();


    if (elementOffsetTop > (scrollTop + viewportHeight)) {
        return 0;
    } else if ((elementOffsetTop + elementHeight) < scrollTop) {
        return 100;
    } else {
        var distance = (scrollTop + viewportHeight) - elementOffsetTop;
        var percentage = distance / ((viewportHeight + elementHeight) / 100);
        return percentage;
    }
}

$win4.trigger('scroll');

var current_slide = 0;
function nextSlide(){
    current_slide = current_slide + 1;
    if (current_slide == 7){
        current_slide = 0;
    }
    if (isMobile){
        $(".slides").css("transform","translate(-"+ 14.25 * current_slide  +"%)");
    }else{
        $("#hacemos").css("transform","translate(-"+ 100 * current_slide  +"%)");
    }
   
}

function prevSlide(){
    current_slide = current_slide - 1;
    if (current_slide == -1){
        current_slide = 6;
    }
    if (isMobile){
        $(".slides").css("transform","translate(-"+ 14.25 * current_slide  +"%)");
    }else{
        $("#hacemos").css("transform","translate(-"+ 100 * current_slide  +"%)");
    }
}

//next_arrow back_arrow

$(".next_arrow").click(function () {
    nextSlide();
});

$(".back_arrow").click(function () {
    prevSlide();
});